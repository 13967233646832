import React, { useEffect, useState } from "react";
import Loader from '../utils/loader';
import { useLocation } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from '../utils/apiCalling';
import { CiCirclePlus } from "react-icons/ci";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CountPage from "./CountPage";

const Index = () => {

    const [emailScreen, setEmailScreen] = useState(true)
    const [loader, setLoader] = useState(false)
    const [qdid, setQdId] = useState('')
    const [password, setPassword] = useState('')
    const path = useLocation();
    const [todayData, setTodayData] = useState([])
    const [newStoredUser, setNewStoredUser] = useState(null)
    const [user, setUser] = useState(null)
    const [logOutModal, setLogOutModal] = useState(false)
    const api = new API

    const navigate = useNavigate()


    const submitEmail = async () => {
        setLoader(true)

        if (!qdid) {
            toast.error("Please Enter QD id", {
                position: toast.POSITION.TOP_RIGHT
            });
            setLoader(false)
        } else if (!password || password !== '123456') {
            toast.error("Please Enter Password", {
                position: toast.POSITION.TOP_RIGHT
            });
            setLoader(false)
        }

        else {

            let data = {
                // name: name,
                qd_id: qdid.toUpperCase()
            }
            const result = await api.post('https://api.doyoursurvey.com:3009/Avanti/SubmitSurvey', data)
            // const result = await api.post('https://api.doyoursurvey.com:3009/client/registerByEmail', data)
            if (result && result.code === 200) {
                // 

                // let pData = { ...prefillData }
                // pData['reviewerData'] = result.data[0]
                // setName(result.data[0].name)
                // setPrefillData(pData)
                // }

                const userData = result.data[0];
                localStorage.setItem("user", JSON.stringify(userData));
                setNewStoredUser(userData.qd_id);
                setUser(userData)
                setEmailScreen(false)
                setLoader(false)
            }
            else {
                toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                setLoader(false)
            }
        }
    }

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem("user"));
        if (storedUser) {
            setNewStoredUser(storedUser.qd_id);
            setUser(storedUser)
            setEmailScreen(false);
        } else {
            setEmailScreen(true);
            setNewStoredUser(null);
            setTodayData([]);
        }
    }, []);

    // let storedUser
    // let newStoredUser
    // if (!emailScreen) {
    //     storedUser = JSON.parse(localStorage.getItem("user"));
    //     newStoredUser = storedUser.qd_id
    // }

    useEffect(() => {
        if (!emailScreen) {
            fetchDataWhenEmailScreenFalse();
        }
    }, [emailScreen]);



    const fetchDataWhenEmailScreenFalse = async () => {
        setLoader(true)
        let data = {
            id: newStoredUser
        }

        try {
            const result = await api.get('https://api.doyoursurvey.com:3009/Avanti/getDetails', true, data);
            if (result && result.code === 200) {
                setTodayData(result.data);
            }
            setLoader(false)
        } catch (error) {
            console.log("errrreeerrr", error);
            toast.error("Error fetching data", {
                position: toast.POSITION.TOP_RIGHT
            });
            setLoader(false)
        }
    };

    function getOut() {
        localStorage.removeItem("user");
        setEmailScreen(true)
        setQdId("")
        setPassword("")
        setLogOutModal(false)
    }

    function goTofeedBackForm() {
        navigate("/FillSurvey")
    }

    function syncButton() {
        fetchDataWhenEmailScreenFalse()
    }

    let currentPage;

    function goToTodayCount() {
        currentPage = 1
        navigate("/count", { state: { totalCount: todayData.basicDatails, pageCount: currentPage } });
    }

    function goToTotalCount() {
        currentPage = 2
        // console.log(todayData.totalDetails,"todayData.totalDetailstodayData.totalDetails")
        navigate("/count", { state: { totalCount: todayData.totalDetails, pageCount: currentPage } });
    }
    // console.log(todayData,"todayDatatodayDatatodayDatatodayDatatodayData");


    return (

        <main className='bg-lgrey position-relative container-fluid p-0'>
            {loader && <Loader />}
            < section id="all-section" className="admin-header position-relative" >

                <nav className="navbar navbar-expand-lg bg-white p-0 border border-bottom">
                    <div className="container-fluid mt-2 mb-2">
                        <a className="navbar-brand1" ><img src={(path.pathname === '/IDFC/Agency' || path.pathname === '/IDFC/Yard') ? "../images/idfc-first-bank-logo.jpg" : "../images/avantiFinancelogo.png"} width="100px" alt="" /></a>

                        <ul className="navbar-nav flex-row align-items-center justify-contents-center">

                        </ul>
                    </div></nav>
            </section >
            <section className='h-100 survey-screen container-fluid'>
                <div className='row justify-content-center'>
                    <div className='col-md-9 mb-5'>
                        <div className='survey-questions bg-white p-3 my-3 com-bsl w-100 mb-5'>
                            {/* <div className='company-name w-100 mb-3'><img src='../images/logo (3).png' className=''></img></div> */}
                            <div className='row justify-content-center' >

                                {emailScreen ?
                                    <div className='con col-md-6'>
                                        <p className=''
                                        >General information</p>
                                        <label>Enter Qd Id :</label><br></br>
                                        <input className='form-control' onChange={(e) => setQdId(e.target.value)} placeholder='Enter your Qd Id'></input>
                                        <label>Enter Password :</label><br></br>
                                        <input type="number" className='form-control' onChange={(e) => setPassword(e.target.value)} placeholder='Enter your password'></input>
                                        <br /><br />
                                        <button className='py-2 mainbtn' onClick={submitEmail}>Submit</button>
                                    </div> :
                                    <>
                                        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                                            <div className="container-fluid">
                                                <a className="navbar-brand pl-1">Dashboard</a>
                                                <span className="pr-3" style={{ cursor: "pointer" }} onClick = {() => logOutModal ? setLogOutModal(false) : setLogOutModal(true) }><img src="./images/logout.png" height={"20px"}></img></span>
                                            </div>
                                        </nav>

                                        <div className="container mt-2">
                                            <div className="card shadow-sm mb-2" onClick={() => { goTofeedBackForm() }} style={{ cursor: "pointer" }}>
                                                <div className="card-body d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <h5 className="card-title">Feedback Form</h5>
                                                        <p className="card-text text-muted">Fill new feedback form</p>
                                                    </div>

                                                    <span><CiCirclePlus style={{ color: "#ee6917", fontSize: "3rem" }} /></span>

                                                </div>
                                            </div>

                                            <div className="card shadow-sm mb-2" onClick={() => { goToTodayCount() }} style={{ cursor: "pointer" }}>
                                                <div className="card-body d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <h5 className="card-title">Today's Count</h5>
                                                        <p className="card-text text-muted">Total count of feedback forms submitted today.</p>
                                                    </div>
                                                    <div className="h4 mb-0 pr-2" style={{ color: "#ee6917", fontSize: "3rem" }}>{loader ? 0 : todayData.todayCount}</div>
                                                </div>
                                            </div>

                                            <div className="card shadow-sm mb-2" onClick={() => { goToTotalCount() }} style={{ cursor: "pointer" }}>
                                                <div className="card-body d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <h5 className="card-title">Total Count</h5>
                                                        <p className="card-text text-muted">Total count of feedback forms submitted at the server.</p>
                                                    </div>
                                                    <div className="h4 mb-0 pr-2" style={{ color: "#ee6917", fontSize: "3rem" }}>{loader ? 0 : todayData.totalCount}</div>
                                                </div>

                                            </div>
                                            {user?.role == "admin" && <div className="container mt-2 text-center">
                                                <Link to={"/mainAdmin"}><button className="btn  w-100 py-2" style={{ backgroundColor: "#ee6917", color: "white" }}>Admin</button></Link>
                                            </div>}
                                            <div className="container mt-2 text-center">
                                                <Link to="/uploadBranchImages"><button className="btn w-100 py-2" style={{ backgroundColor: "#ee6917", color: "white" }}>Upload Branch Images</button></Link>
                                            </div>
                                            <div className="container mt-2 text-center">
                                                <button className="btn btn-primary w-100 py-2" onClick={() => { syncButton() }}>Sync Now</button>
                                            </div>
                                        </div >
                                    </>
                                }

                                <div className='w-100  powered-btn'>
                                    <a href='https://qdegrees.com/' target="_blank"> <div className='mainbtnblue m-2 position-fixed bottom-0 end-0'><small className='f-12'>Powered by</small>&nbsp;<b className='f-14'>DoYourSurvey</b></div></a>
                                </div>
                                <ToastContainer />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {logOutModal && <div class="modal fade show" style={{display:"block"}}  aria-labelledby="exampleModalLabel">
                <div class="modal-dialog">
                    <div class="modal-content" style={{left: '2%',top: '7rem', borderRadius:"1.5rem"}}>
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="exampleModalLabel">Log out</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={()=>setLogOutModal(false)}></button>
                        </div>
                        <div class="modal-body">
                            Are you sure you want to logout ?
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" onClick={()=>setLogOutModal(false)}>Cancel</button>
                            <button type="button" class="btn btn-primary" onClick={getOut}>Logout</button>
                        </div>
                    </div>
                </div>
            </div>}

        </main >

    )

}

export default Index