import React, { useState, useEffect } from 'react'
import { FaBars } from "react-icons/fa";
import Header from './Header'
import AdminPage from './Edit';
import ImageView from './ImageView';
import DownloadExcel from './downloadExcel';
import AddAgent from './addAgent'


function MainAdmin() {

    const [count, setCount] = useState(0)



    const toggleNav = () => {
        const sidebar = document.getElementById("mySidebar");
        sidebar.classList.toggle("closed");

        if (window.innerWidth <= 768) {
            sidebar.classList.toggle("open");
        }
    };



    return (
        <>
            <Header />
            <div className='bodyMainAdmin'>
                <div id="mySidebar" class="sidebar" style={{ top: "10vh" }}>
                    <div class="sidebar-header">
                        <h3>Menu</h3>
                        <button class="toggle-btn" onClick={() => { toggleNav() }}>
                            <FaBars />
                        </button>
                    </div>
                    <a className={count==1?"bg-orange text-white":""} onClick={() => { setCount(1) }}> <span>Add Agent</span></a>
                    <a className={count==2?"bg-orange text-white":""} onClick={() => { setCount(2) }}><span>Report</span></a>
                    <a className={count==3?"bg-orange text-white":""} onClick={() => { setCount(3) }}><span>Image</span></a>
                    <a className={count==4?"bg-orange text-white":""} onClick={() => { setCount(4) }}><span>Edit</span></a>
                </div>


                <div id="main">
                    {count == 1 && (
                        <div className='container'>
                           <AddAgent/>
                        </div>
                    )}

                    {count == 2 && (
                        <div className='container'>
                            <DownloadExcel/>
                        </div>
                    )}

                    {count == 3 && (
                        <div className='container'>
                            <ImageView/>
                        </div>
                    )}

                    {count == 4 && (
                        <div className='container'>
                            <AdminPage/>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default MainAdmin
